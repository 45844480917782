<template>
  <moe-page title="退款详情">
    <div class="df jcsb padding-20">
      <div class="df3">

        <!-- 退款关闭 / 售后关闭 -->
        <template v-if="[REFUND_STATE.REFUND_COMPLETED].includes(refundData.status)">
          <div class="font-20 fwb mb-30">退款关闭</div>
          <div class="mb-30">
            <template v-if="refundData.cancelType === 'SYSTEM'">商家已发货，本次退款关闭</template>
            <template v-else-if="refundData.cancelType === 'TIMEOUT'">因买家超时未响应，退款申请关闭</template>
            <template v-else>因买家撤销，退款申请关闭</template>
          </div>
          <div class="mb-10">完结时间： {{ $moe_time.getTimeStamp(refundData.updateTime, 'YYYY年MM月DD日 - HH时mm分ss秒') }}</div>
          <div class="mb-10">
            <template v-if="refundData.cancelType === 'SYSTEM'">关闭原因：商家已发货，本次退款关闭。</template>
            <template v-else-if="refundData.cancelType === 'TIMEOUT'">关闭原因：买家超时未响应。</template>
            <template v-else>关闭原因：买家主动撤销了本次售后服务申请。</template>
          </div>
        </template>

        <!-- 退款待处理 / 售后申请中 -->
        <template v-if="[REFUND_STATE.REFUND_PENDING].includes(refundData.status)">
          <div class="df aife mb-20">
            <div class="font-20 fwb mr-20">{{`请处理${$moe_format.getRefundType(refundData.type)}申请`}}</div>
            <div class="color-primary">还剩 {{ refundData.countdown }}</div>
          </div>
          <div class="mb-20">请及时联系买家协议退款事宜</div>

          <div class="mb-40" v-if="refundData.type === 1">
            <div>如果你同意，将直接退款给买家。</div>
            <div>如果你拒绝，买家可以重新申请售后。</div>
            <div>协议退货退款： 如果商品已经签收，您可以与买家协商退货退款。</div>
            <div>如果你逾期未处理，视作同意买家申请，系统将自动退款给买家。</div>
          </div>

          <div class="mb-40" v-if="refundData.type === 2">
            <div>如果你同意，请将正确收货地址提供给买家。</div>
            <div>如果你拒绝，买家可以重新提交售后申请。</div>
            <div>如果你逾期未处理，视作同意买家申请，系统将自动同意买家退货。</div>
          </div>

          <div v-if="refundData.type === 1">
            <el-button type="primary" @click="req_orderReturn('退款')">同意退款</el-button>
            <el-button type="success" @click="req_returnUpdate()">协商退货退款</el-button>
            <el-button type="warning" @click="showReturnRefuse = true;">拒绝申请</el-button>
          </div>

          <div v-else-if="refundData.type === 2">
            <el-button type="primary" @click="showAgreeReturn = true;">同意退货</el-button>
            <el-button type="warning" @click="showReturnRefuse = true;">拒绝退货</el-button>
          </div>
        </template>

        <!-- 待买家发货 / 退货中 , 待商家收货 / 退货审核中 -->
        <template v-if="[REFUND_STATE.RETURN_IN_PROGRESS, REFUND_STATE.RETURN_REVIEW].includes(refundData.status)">
          <template v-if="refundData.status === REFUND_STATE.RETURN_REVIEW">
            <div class="df aife mb-20">
              <div class="font-20 fwb mr-20">待商家收货</div>
              <div class="color-primary">还剩 {{ refundData.countdown }}</div>
            </div>

            <div class="df aic mb-30">同意退款后<div class="color-danger ml-5">¥ {{ refundData.money }}</div>元将立即返还买家账户</div>

            <div class="mb-40">
              <div>买家已退货，请关注退货物流状态，当退件显示签收/拒收且您的剩余时长大于72小时，剩余处理时长将缩短为72小时</div>
              <div>如果您逾期未处理，系统将自动同意退款</div>
              <div>收到货物后，如果您同意退款，款项将原路退回买家账户</div>
              <div>如果货物有问题，您可以拒绝退款</div>
            </div>

            <div>
              <el-button type="primary" @click="req_orderReturn('退货退款')">确认收货，同意退款</el-button>
              <el-button type="warning" @click="showReturnRefuse = true;">拒绝退款</el-button>
            </div>
          </template>

          <template v-else-if="refundData.status === REFUND_STATE.RETURN_IN_PROGRESS">
            <div class="df aife mb-20">
              <div class="font-20 fwb mr-20">待买家发货</div>
              <div class="color-primary">还剩 {{ refundData.countdown }}</div>
            </div>

            <div class="mb-40">
              <div>如果买家在超时结束前未退货，退货申请将自动关闭</div>
              <div>收到买家退货时，请验货后同意退款</div>
            </div>

            <el-button type="primary" @click="req_orderReturn('退货退款')">已收到货，同意退款</el-button>
          </template>
        </template>

        <!-- 商家拒绝收货,待买家处理 / 退货申请拒绝 -->
        <template v-if="[REFUND_STATE.REFUND_REJECT, REFUND_STATE.WAITING_RESPONSE].includes(refundData.status)">
          <div class="df aife mb-20">
            <div class="font-20 fwb mr-20" v-if="[REFUND_STATE.REFUND_REJECT].includes(refundData.status)">商家拒绝退款，待买家处理</div>
            <div class="font-20 fwb mr-20" v-if="[REFUND_STATE.WAITING_RESPONSE].includes(refundData.status)">商家拒绝收货，待买家处理</div>
            <div class="color-primary">还剩 {{ refundData.countdown }}</div>
          </div>

          <div class="mb-30">买家修改申请后，商家需要重新处理，买家超时未修改售后将关闭。</div>
          <div class="mb-10">拒 绝 时 间：{{ $moe_time.getTimeStamp(refundData.updateTime, 'YYYY年MM月DD日 - HH时mm分ss秒') }}</div>
          <div class="mb-10">拒 绝 原 因：{{ operationRationaleCoumputed }}</div>
          <div class="mb-10">拒 绝 说 明：{{ refundData.operationExplain }}</div>

          <div v-if="[REFUND_STATE.REFUND_REJECT].includes(refundData.status)">
            <el-button type="primary" @click="req_orderReturn('退款')">同意退款</el-button>
          </div>
          <div v-if="[REFUND_STATE.WAITING_RESPONSE].includes(refundData.status)">
            <el-button type="primary" @click="req_orderReturn('退货退款')">已收到货,同意退款</el-button>
          </div>
        </template>

        <!-- 退款成功 / 售后成功 -->
        <template v-if="[REFUND_STATE.REFUND_SUCCESSFUL].includes(refundData.status)">
          <div class="font-20 fwb mb-30">退款成功</div>
          <div class="mb-30 df aic">已成功退款<div class="color-danger ml-5">¥ {{ refundData.money }}</div></div>
          <div>退 款 时 间：{{ $moe_time.getTimeStamp(refundData.updateTime, 'YYYY年MM月DD日 - HH时mm分ss秒') }}</div>
          <div class="df aic">
            退 款 方 式：
            <div :class="$moe_format.getpayType(refundData.payType).color">
              <moe-icon :name="$moe_format.getpayType(refundData.payType).icon" class="mr-5"></moe-icon>
              <span>{{ $moe_format.getpayType(refundData.payType).title }}</span>
            </div>
          </div>
        </template>

      </div>
      <div class="df2">
        <moe-steps :active="activeComputed" :stepsList="refundStepsList[refundData.type]"></moe-steps>
      </div>
    </div>

    <moe-tabs :tabsList="[{ label: '退款详情' }, { label: '物流详情' }, { label: '协商记录' }]" type="border-card">
      <template #0>
        <moe-grid :column="3">
          <moe-describe-list title="售后商品" :col="1">
            <moe-describe-item label="">
              <div class="df aic jcc mb-30">
                <moe-image :src="refundData.coverUrl" width="100px" height="100px" />
                <div class="df1 ml-10">
                  <div class="fwb mb-10">{{ refundData.goodsName }}</div>
                  <p class="font-12 color-info mb-10">{{ refundData.specValueStr }}</p>
                  <div class="font-12 color-info">商品编号: {{ refundData.goodsId }}</div>
                </div>
              </div>

              <div class="df aic jcc mb-30" v-for="(item, index) in refundData.giftOrderItems" :key="index">
                <moe-image :src="item.coverUrl" width="100px" height="100px" />
                <div class="df1 ml-10">
                  <div class="fwb mb-10">{{ item.goodsName }}</div>
                  <div class="df aic mb-10">
                    <p class="font-12 color-info mr-10 wsn">{{ item.specValueStr }}</p>
                    <p class="b-primary color-primary pl-5 pr-5 h-25 lh-25 tac wsn">{{ `赠品 x${item.number}` }}</p>
                  </div>
                  <div class="font-12 color-info">商品编号: {{ item.goodsId }}</div>
                </div>
              </div>
            </moe-describe-item>
          </moe-describe-list>

          <moe-describe-list title="售后信息" :col="1">
            <moe-describe-item label="退款编号">{{ refundData.refundNo }}</moe-describe-item>
            <moe-describe-item label="售后方式">{{ refundData.type === 1 ? '仅退款' : '退货退款' }}</moe-describe-item>
            <moe-describe-item label="收货状态">{{ deliveryStatus(refundData.deliveryStatus) }}</moe-describe-item>
            <moe-describe-item label="退款金额"><div class="color-danger">{{ `¥ ${refundData.money}` }}</div></moe-describe-item>
            <moe-describe-item label="联系方式">{{ refundData.userPhone }}</moe-describe-item>
            <moe-describe-item label="退款原因">{{ formatAfterSalesCause(refundData.afterSalesCause) }}</moe-describe-item>
            <moe-describe-item label="退款说明">{{ refundData.additionCause }}</moe-describe-item>
            <moe-describe-item label="售后凭证">
              <div class="df aic" v-if="refundData.afterSalesVoucher && refundData.afterSalesVoucher.length">
                <moe-image class="mr-5" :src="item" v-for="(item, index) in refundData.afterSalesVoucher" :key="index" width="50px" height="50px" />
              </div>
              <div v-else>-</div>
            </moe-describe-item>
            <moe-describe-item label="申请时间">{{ $moe_time.getTimeStamp(refundData.createTime) }}</moe-describe-item>
          </moe-describe-list>

          <moe-describe-list title="购买信息" :col="1">
            <moe-describe-item label="用户">{{ refundData.contact }}</moe-describe-item>
            <moe-describe-item label="商品单价">
              <div class="df aic">
                <div class="color-danger mr-10">{{ `¥ ${refundData.payPrice}` }}</div>
                <div>{{ `x ${refundData.number} 件` }}</div>
              </div>
            </moe-describe-item>
            <moe-describe-item label="实付金额"><div class="color-danger">{{ `¥ ${$moe_math.mathAdd(refundData.totalPrice, refundData.freight)}` }}</div></moe-describe-item>
            <moe-describe-item label="运费"><div :class="{ 'color-danger': $moe_math.mathGreaterThan(refundData.freight) }">{{ $moe_math.mathGreaterThan(refundData.freight) ? `¥  ${refundData.freight}` : '包邮' }}</div></moe-describe-item>
            <moe-describe-item label="发货状态">{{ expressStatus(refundData.expressStatus) }}</moe-describe-item>
            <moe-describe-item label="订单编号">{{ refundData.orderNo }}</moe-describe-item>
            <moe-describe-item label="支付时间">{{ $moe_time.getTimeStamp(refundData.payTime) }}</moe-describe-item>
          </moe-describe-list>
        </moe-grid>
      </template>
      <template #1>
        <moe-grid :column="2">
          <moe-describe-list title="发货物流" :col="1">
            <moe-describe-item label="物流公司">{{ refundData.expressName }}</moe-describe-item>
            <moe-describe-item label="物流单号">{{ refundData.expressNo }}</moe-describe-item>
            <moe-describe-item label="收件人">{{ refundData.contact }}</moe-describe-item>
            <moe-describe-item label="联系方式">{{ refundData.mobile }}</moe-describe-item>
            <moe-describe-item label="收货地址">{{ refundData.address }}</moe-describe-item>
            <moe-describe-item label="物流动态">
              <el-button v-if="refundData.expressNo" @click="getExpress(refundData.expressNo)">发货物流动态</el-button>
            </moe-describe-item>
          </moe-describe-list>
          <moe-describe-list title="退货信息" :col="1">
            <moe-describe-item label="物流公司">{{ refundLogistics.name }}</moe-describe-item>
            <moe-describe-item label="物流单号">{{ refundLogistics.expressNo }}</moe-describe-item>
            <moe-describe-item label="收件人">{{ refundLogistics.contact }}</moe-describe-item>
            <moe-describe-item label="联系方式">{{ refundLogistics.mobile }}</moe-describe-item>
            <moe-describe-item label="收货地址">{{ refundLogistics.address }}</moe-describe-item>
            <moe-describe-item label="物流动态">
              <el-button v-if="refundLogistics.expressNo" @click="getExpress(refundLogistics.expressNo)">退货物流动态</el-button>
            </moe-describe-item>
          </moe-describe-list>
        </moe-grid>
      </template>
      <template #2>
        <div>
          <moe-steps className="logisticsBox" :stepsList="refundHistoryList" direction="vertical" simple :isStatus="true"></moe-steps>
        </div>
      </template>
    </moe-tabs>

    <moe-dialog :show="showLogistics" title="物流动态" width="50%" @close="dialogClose()" :autoHeight="true">
      <div class="pl-30 pr-30 pt-30 pb-30">
        <moe-steps className="logisticsBox" :stepsList="stepsList" direction="vertical" simple :isStatus="true"></moe-steps>
      </div>
    </moe-dialog>

    <moe-dialog :show="showAgreeReturn" title="同意退货申请" width="50%" @close="dialogClose()" :autoHeight="true">
      <moe-form ref="agreeReturnForm" :showBack="false" :model="agreeReturnParams" :rules="rules">
        <el-form-item label="退货地址" prop="areaId">
          <el-select v-model="agreeReturnParams.areaId" placeholder="退货地址">
            <el-option v-for="(item, index) in addressList" :key="index" :value="item.id" :label="`收货人：${item.contacts}${item.contactPhone} 收货地址：${item.province}${item.city}${item.county}${item.address}`">
              <div class="df">
                <div>收货人：{{ item.contacts }}  {{ item.contactPhone }}</div>
                <div class="ml-5">收货地址：{{ item.province }}{{ item.city }}{{ item.county }}{{ item.address }}</div>
              </div>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="退货说明">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="agreeReturnParams.returnGoodsExplain"
            :autosize="{ minRows: 3, maxRows: 4 }"
            maxlength="200"
            show-word-limit>
          </el-input>
        </el-form-item>
      </moe-form>

      <template slot="footer">
        <el-button @click="dialogClose()">取 消</el-button>
        <el-button :loading="agreeReturnLoading" type="primary" @click="req_agreeReturnGoods()">确 定</el-button>
      </template>
    </moe-dialog>

    <moe-dialog :show="showReturnRefuse" :title="refundData.type === 1 ? '拒绝退款' : '拒绝退货申请'" width="50%" @close="dialogClose()" :autoHeight="true">
      <moe-form ref="returnRefuseForm" :showBack="false" :model="returnRefuseParams" :rules="rules">
        <el-form-item label="拒绝原因" prop="operationRationale">
          <el-select v-model="returnRefuseParams.operationRationale">
            <el-option v-for="(item, index) in refuseReasonList" :key="index" :label="item.reason" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="拒绝说明" prop="operationExplain">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="returnRefuseParams.operationExplain"
            :autosize="{ minRows: 3, maxRows: 4 }"
            maxlength="200"
            show-word-limit>
          </el-input>
        </el-form-item>
      </moe-form>

      <template slot="footer">
        <el-button @click="dialogClose()">取 消</el-button>
        <el-button :loading="returnRefuseLoading" type="primary" @click="req_returnRefuse()">确 定</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
import { REFUND_STATE } from '@/views/Order/Order.type'
import { getRefundFromType, refundOrderRecordStatusOptions } from '@/views/Order/Order.format'
export default {
  name: 'RefundOrderDetail',
  computed: {
    refundStepsList() {
      const { id, type } = this.refundData
      if (!id || !type) return { undefined: [] }
      return {
        1: [{ title: '买家申请退款' }, { title: '卖家处理退款申请' }, { title: '退款完毕' }],
        2: [{ title: `买家申请${this.$moe_format.getRefundType(type)}` }, { title: '卖家处理退货申请' }, { title: '买家退货' }, { title: '退款完毕' }],
      }
    },
    /**
      * 退款状态
      * 1.取消订单/撤销申请 2.等待退款 3.退款成功 4.等待退货 5.退货中 6.退货审核 7.退货退款成功 8.等待客户响应/拒绝退款 9.退款完结
      */
    activeComputed() {
      const { status, type } = this.refundData
      let active = 0;

      switch (status) {
        case REFUND_STATE.REFUND_PENDING:
          active = 1;
          break;
        case REFUND_STATE.REFUND_REJECT:
          active = 1;
          break;
        case REFUND_STATE.RETURN_IN_PROGRESS:
          active = 2;
          break;
        case REFUND_STATE.RETURN_PENDING:
          active = 1;
          break;
        case REFUND_STATE.RETURN_REVIEW:
          active = 3;
          break;
        case REFUND_STATE.REFUND_SUCCESSFUL:
          active = 3;
          break;
      }

      return active;
    },
    operationRationaleCoumputed() {
      let findItem = this.refuseReasonList.find(({ id }) => Number(this.refundData.operationRationale) === id);
      if (findItem) {
        return findItem.reason
      }
      return '';
    },
    /** 获取当前退款状态的上一次状态 */
    getRefundUpStatusCoumputed() {
      let refundHistoryCount = this.refundHistoryList.length
      if (!refundHistoryCount || refundHistoryCount && refundHistoryCount < 2) {
        return ''
      }
      return this.refundHistoryList[refundHistoryCount - 2].data.status
    },
  },

  data() {
    const verify = this.$moe_verify.verifyForm;
    return {
      REFUND_STATE,
      getRefundFromType,
      refundOrderRecordStatusOptions,
      refundData: {},
      refundLogistics: {},    //退货信息
      refundReasonList: [],   //退款原因
      refuseReasonList: [],   //拒绝原因
      // 同意退货申请
      showAgreeReturn: false,
      agreeReturnLoading: false,
      agreeReturnParams: {
        areaId: '',
        returnGoodsExplain: '',
      },
      //拒绝退款/拒绝退货申请
      showReturnRefuse: false,
      returnRefuseLoading: false,
      returnRefuseParams: {
        operationRationale: '', //操作原因
        operationExplain: '',   //操作说明
      },

      rules: {
        operationRationale: verify.isEmpty('请选择拒绝原因'),
        operationExplain: verify.isEmpty('请输入拒绝说明'),

        areaId: verify.isEmpty('请选择退货地址'),
      },

      countdownInterval: null,

      addressList: [],
      showLogistics: false,
      stepsList: [],

      refundHistoryList: []
    }
  },
  methods: {
    /** 协商退货退款 */
    req_returnUpdate() {
      this.$moe_layer.confirm(`<p>仅退款申请将转换为退货退款申请<br />请与买家协商，确认达成一致后操作</p>`, () => {
        this.$moe_api.ORDER_API.returnUpdate({
          type: 2,
          id: this.refundData.id
        }).then((data) => {
          if (data && data.code === 200) {
            this.$moe_msg.success('协商退货退款成功！');
            this.$router.go(-1);
            this.returnListDetails();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
    /** 同意退货 */
    req_agreeReturnGoods() {
      this.$refs['agreeReturnForm'].validate(() => {
        this.agreeReturnLoading = true;
        this.$moe_api.ORDER_API.agreeReturnGoods({
          ...this.agreeReturnParams,
          id: this.refundData.id
        }).then((data) => {
          if (data.code === 200) {
            this.showAgreeReturn = false;
            this.$moe_msg.success('同意退货成功！');
            this.$router.go(-1);
            this.returnListDetails();
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.agreeReturnLoading = false
        })
      })
    },
    /** 同意退款 */
    req_orderReturn(orderReturnText) {
      this.$confirm(`您确定同意"${orderReturnText}"？`, '系统提示', {
        closeOnClickModal: false,
        // distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = instance.cancelButtonLoading = true;
            instance.confirmButtonText = instance.cancelButtonText = '请稍候...';
            this.$moe_api.ORDER_API.orderReturn({
              id: this.refundData.id
            }).then((data) => {
              if (data && data.code === 200) {
                this.$moe_msg.success(`同意${orderReturnText}成功！`);
                this.$router.go(-1);
                this.returnListDetails();
              } else {
                if (data) {
                  this.$moe_msg.error(data.message);
                }

              }
            }).finally(() => {
              instance.confirmButtonLoading = instance.cancelButtonLoading = false;
              instance.confirmButtonText = '确定';
              instance.cancelButtonText = '取消';
              done();
            })
          } else if (action === 'cancel') {
            done();
          }
        }
      })
    },
    /** 售后拒绝 */
    req_returnRefuse() {
      console.log(this.$refs)
      this.$refs['returnRefuseForm'].validate(() => {
        this.returnRefuseLoading = true;
        this.$moe_api.ORDER_API.returnRefuse({
          ...this.returnRefuseParams,
          id: this.refundData.id
        }).then((data) => {
          if (data.code === 200) {
            this.showReturnRefuse = false;
            this.$moe_msg.success('拒绝成功');
            this.$router.go(-1);
            this.returnListDetails();
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.returnRefuseLoading = false;
        })
      })
    },
    /** 弹框关闭 */
    dialogClose() {
      this.showLogistics = this.showAgreeReturn = this.showReturnRefuse = false;
      this.$refs['agreeReturnForm'] && this.$refs['agreeReturnForm'].resetFields();
      this.$refs['returnRefuseForm'] && this.$refs['returnRefuseForm'].resetFields();
    },
    /** 用来格式化内容 */
    formatAfterSalesCause(afterSalesCause) {
      return this.refundReasonList.find(({ id }) => afterSalesCause === id)?.reason || '-'
    },
    foramtOperationRationale(operationRationale) {
      return this.refuseReasonList.find(({ id }) => Number(operationRationale) === id)?.reason || '-'
    },
    expressStatus(expressStatus) {
      return this.$moe_data.expressStatus.find(({ value }) => expressStatus === value)?.label || '-'
    },
    deliveryStatus(deliveryStatus) {
      if(!deliveryStatus) return '-'
      if (deliveryStatus === '1') return '已收货'
      if (deliveryStatus === '2') return '未收货'
    },
    /** 获取拒绝原因列表
      * type 1.退款原因 2.拒绝原因
      */
    queryShopRefundReasons(type) {
      this.$moe_api.ORDER_API.queryShopRefundReasons({ type }).then((data) => {
        if (data.code === 200) {
          if (type === 1) {
            this.refundReasonList = data.result;
          } else if (type === 2) {
            this.refuseReasonList = data.result;
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 查询物流 */
    getExpress(expressNo) {
      this.$moe_api.ORDER_API.getExpress({ expressNo }).then((data) => {
        if (data.code === 200) {
          this.showLogistics = true;
          this.stepsList = JSON.parse(data.result.expressData).map((item, index) => {
            return {
              ...item,
              title: `【${item.status}】 ${item.context}`,
              description: item.ftime,
              status: index === 0 ? 'success' : 'process',
              icon: "el-icon-place"
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 查询退货物流 */
    getExpressRefund(refundNo) {
      this.$moe_api.ORDER_API.getExpressRefund({ refundNo }).then((data) => {
        if (data.code === 200) {
          if (data.result) {
            let r = data.result;
            this.refundLogistics = {
              name: r.name,
              expressNo: r.expressNo,
              contact: r.contact,
              mobile: r.mobile,
              address: r.address,
            };
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 售后详情 */
    returnListDetails() {
      let params = {}
      if (this.$route.query.id) {
        params.id = this.$route.query.id
      }
      if (this.$route.query.itemOrderNo) {
        params.itemOrderNo = this.$route.query.itemOrderNo
      }
      this.$moe_api.ORDER_API.returnListDetails(params).then((data) => {
        if (data.code === 200) {
          const r = data.result;

          this.refundData = {
            ...r,
            afterSalesVoucher: r.afterSalesVoucher ? r.afterSalesVoucher.split(',') : [],
          }

          if (r.countdown) {
            this.refundData.countdown = this.$moe_time.countDown(r.countdown, '', 'minutes');
            let interTime = 60 * 1000;
            this.countdownInterval = setInterval(() => {
              this.refundData.countdown = this.$moe_time.countDown(r.countdown - interTime, '', 'minutes');
            }, interTime);
          }
          this.getExpressRefund(r.refundNo);
          this.req_refundHistory(r.refundNo);
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 退款收货地址 */
    areasReturnList() {
      this.$moe_api.ORDER_API.areasReturnList().then((data) => {
        if (data.code === 200) {
          this.addressList = data.result;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 售后协商历史 */
    req_refundHistory(refundNo) {
      this.$moe_api.ORDER_API.refundHistory({ refundNo }).then((data) => {
        if (data.code === 200) {
          let list = data.result.list;
          this.refundHistoryList = list.map((item, index) => {
            let { status, refundNo, type, money, afterSalesCause, additionCause, afterSalesVoucher, contact, mobile, address, expressName, expressNo, operationRationale, operationExplain, cancelType } = item.data,
              description = [{ label: '', value: this.$moe_time.getTimeStamp(item.createTime) }];

            switch (status) {
              case REFUND_STATE.REFUND_PENDING:
                description = [
                  ...description,
                  { label: '退款编号', value: refundNo },
                  { label: '售后方式', value: type === 1 ? '仅退款' : '退货退款' },
                  { label: '退款金额', value: `¥ ${money}` },
                  { label: '退款原因', value: this.formatAfterSalesCause(afterSalesCause) },
                  { label: '退款说明', value: additionCause },
                  { label: '售后凭证', value: afterSalesVoucher ? afterSalesVoucher.split(',') : [] }
                ]
                break;
              case REFUND_STATE.RETURN_IN_PROGRESS:
                description = [
                  ...description,
                  { label: '退货地址', value: `${contact} ${mobile} ${address}` }
                ]
                break;
              case REFUND_STATE.RETURN_REVIEW:
                description = [
                  ...description,
                  { label: '物流公司', value: expressName },
                  { label: '物流单号', value: expressNo }
                ]
                break;
              case REFUND_STATE.REFUND_SUCCESSFUL:
                description = [
                  ...description,
                  { label: '退款金额', value: `¥ ${money}` },
                ]
                break;
              case REFUND_STATE.REFUND_COMPLETED:
                if (cancelType === 'MANUAL') {
                  description = [
                    ...description,
                    { value: '取消退款申请，退款已关闭，交易将正常进行' }
                  ]
                } else if (cancelType === 'TIMEOUT') {
                  description = [
                    ...description,
                    { value: '买家超时无响应，退款关闭' }
                  ]
                } else if (cancelType === 'SYSTEM') {
                  description = [
                    ...description,
                  ]
                } else {
                    description = [
                    ...description,
                    { value: '售后关闭' }
                  ]
                }
                break;
              default:
                description = [
                  ...description,
                  { label: '拒绝原因', value: this.foramtOperationRationale(operationRationale) },
                  { label: '拒绝说明', value: operationExplain }
                ]
                break;
            }

            let title = '';
            if (item.fromType === 'MERCHANT' && status === REFUND_STATE.REFUND_PENDING) {
              title = '商家协商退货退款申请，等待商家处理'
            } else {
              if (list.filter(({ data }) => data.status === status).length > 1) {
                for (let i = index; i < list.length; i++) {
                  if (index < i) {
                    title = '修改了退款申请，等待商家处理'
                    continue;
                  } else {
                    title = this.refundOrderRecordStatusOptions(status);
                    continue;
                  }
                }
              } else {
                if (item.data.cancelType === 'SYSTEM') {
                  title = '商家已发货，本次退款关闭';
                } else {
                  title = this.refundOrderRecordStatusOptions(status);
                }
              }
            }

            return {
              ...item,
              title: `【${this.getRefundFromType(item.fromType)}】 ${title}`,
              description,
              status: index === 0 ? 'success' : 'process',
              icon: 'el-icon-document',
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
  },
  mounted() {
    this.queryShopRefundReasons(1);
    this.returnListDetails();
    this.queryShopRefundReasons(2);
    this.areasReturnList();
  },
  beforeDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }
}
</script>