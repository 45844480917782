/** 获取售后订单来源类型 */
export const getRefundFromType = (key) => {
  const data = {
    USER: '买家',
    MERCHANT: '商家',
    default: '',
  };
  return data[key] || data['default'];
}

/** 退款订单协商记录状态 */
export const refundOrderRecordStatusOptions = (key) => {
  const data = {
    REFUND_PENDING: '发起了退款申请，等待商家处理',
    REFUND_REJECT: '拒绝了本次申请，等待买家修改',
    RETURN_IN_PROGRESS: '已同意退款申请，等待买家退货',
    WAITING_RESPONSE: '商家拒绝收货',
    RETURN_REVIEW: '已退货，等待商家确认收货',
    REFUND_SUCCESSFUL: '商家同意退款给买家，本次售后结束',
    REFUND_COMPLETED: '售后关闭',
    default: '',
  };

  return data[key] || data['default'];
}

export default {
  getRefundFromType,
  refundOrderRecordStatusOptions,
};