export const REFUND_STATE = {
  /** 退款待处理 / 售后申请中 */
  REFUND_PENDING: 'REFUND_PENDING',
  /** 商家拒绝退款,待买家处理 / 售后申请拒绝 */
  REFUND_REJECT: 'REFUND_REJECT',
  /** 待买家发货 / 退货中 */
  RETURN_IN_PROGRESS: 'RETURN_IN_PROGRESS',
  /** 商家拒绝收货,待买家处理 / 退货申请拒绝 */
  WAITING_RESPONSE: 'WAITING_RESPONSE',
  /** 待商家收货 / 退货审核中 */
  RETURN_REVIEW: 'RETURN_REVIEW',
  /** 退款成功 / 售后成功 */
  REFUND_SUCCESSFUL: 'REFUND_SUCCESSFUL',
  /** 退款关闭 / 售后关闭 */
  REFUND_COMPLETED: 'REFUND_COMPLETED',
};

export default {
  REFUND_STATE,
};